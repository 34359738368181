import React from 'react';
import {Typography, TypographyProps, withTheme} from '@material-ui/core'
import {I18nContext} from '../../i18n';
import {shouldEncode, encodeHTML} from '@ornery/web-components';

/**
 * I18nMessage is used to show a string in the UI.
 *
 * NOTES ON USAGE: 
 * There is functionality to show a localized string, based on the locale config, 
 * as well as looking up labels for an id.
 * Most of this logic is in @ornery/web-components/src/i18n.js.
 * This functionality is confusing, buggy, not tested, not necessary, and should be considered deprecated.
 * 
 * Passing in textValue skips this logic, and shows the provided text directly. 
 * This should be preferred for future usages.
 * 
 * I8nMessage cannot be removed, as it used throughout the portal, and customers may have styled against it.
 * Removing it would be a breaking change.
 * 
 */

interface I18nMessageProps extends TypographyProps {
    [key: string]: any,

    theme?: any;
    htmlEncode?: boolean;
    /**
     * If present, show this text value without any modifications. 
     */
    textValue?: string;
    id: string,
    href?: any,
    as?: any,
    shallow?: any,
    scrollNavLink?: boolean,
    values?: any,
    className?: string,
    innerClassName?: string,
    Component?: React.ReactNode
}

export class I18nMessage extends React.Component<I18nMessageProps, {}> {
    static defaultProps = {
        values: {},
        id: "",
        className: "",
        Component: Typography,
        htmlEncode: false,
        scrollNavLink: true
    }
    static contextType = I18nContext;

    render() {
        let {id, values, Component, htmlEncode, theme = {}, ...componentProps} = this.props as any;
        const {color} = this.props.color ? {color: this.props.color} : (theme.i18nMessage || 'inherit')

        const pluralDefault = values["@default"] || '#'
        const pluralKey = values["@plural"] || 'plural';
        const plurals = this.context.getAll(pluralKey) || {};
        Object.entries(plurals).forEach(([k, v]: any) => {
            const range = k.split('-');
            let start = parseInt(range[0], 10);
            let end = parseInt(range[1], 10);
            for (let i = start; i <= end; i++) {
                plurals[i] = v;
            }
        })
        const pluralValueKey = values['@key'] || plurals["@key"] || 'count';
        const pluralValue = values[pluralValueKey];
        values[`@${pluralValueKey}`] = (pluralValue != null ? pluralValue.toString() : '#');
        if (plurals[pluralValue] || plurals[pluralDefault]) {
            values[`@${pluralKey}`] = plurals[pluralValue] || plurals[pluralDefault];
        }
        const messageProps = {
            id: this.props.id,
            class: 'mui-typography_root',
            shadow: "false",
            encode: htmlEncode,
            "data-text-value": this.props.textValue,
            "data-values": JSON.stringify(values)
        } as any;
        messageProps.dangerouslySetInnerHTML = { __html: this.context.get(this.props.id, values)}
        componentProps.scroll = this.props.scrollNavLink
        return (
            <Component {...componentProps} color={color}>
                {this.props.children}
                <i18n-message {...messageProps} />
            </Component>
        );
    }
}

export default withTheme(I18nMessage as any);